import { createRouter,createWebHashHistory,createWebHistory} from "vue-router";
import { defineAsyncComponent } from 'vue'
import CM from '../util/common.js'
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
  		path: '',
  		redirect: '/index'
  	},
    {
  		path: '/',
  		redirect: '/index'
  	},
  	{
  		path: '/home',
  		component: () => import('../page/home.vue'),
  		children:[
        {
      		path: '/index',
          component: () => import('../page/pc/booking.vue'),
          // {
          //   if(CM.checkMobile()){
          //     return import(`../page/mobile/HelloWorld.vue`)
          //   }else{
          //     return import(`../page/pc/HelloWorld.vue`)
          //   }
          // },
          meta: {
            title: 'MR.Tホテル予約',
            showTab: true
          },
      	},
        {
      		path: '/hoteldetail',
          name:"hoteldetail",
          // component: () => import('../page/mobile/hoteldetail.vue'),
          component(){
            if(CM.checkMobile() || CM.checkWindowsize()){
              return import(`../page/mobile/hoteldetail.vue`)
            }else{
              return import('../page/pc/hoteldetail.vue')
            }
          },
          meta: {
            title: '予約',
          },
      	},
        {
      		path: '/hoteldetailStatic',
          name:"hoteldetailStatic",
          component: () => import('../page/mobile/hoteldetailStatic.vue'),
          meta: {
            title: 'ホテル詳細',
          },
      	},
        {
      		path: '/bookpage',
          name:"bookpage",
          component: () => import('../page/pc/bookpage.vue'),
          meta: {
            title: '予約画面',
          },
      	},
        {
      		path: '/paytype',
          name:"paytype",
          component: () => import('../page/pc/paytype.vue'),
          meta: {
            title: '支払方法',
          },
      	},
        {
      		path: '/mypage',
          name:"mypage",
          // component: () => import('../page/mobile/mypage.vue'),
          component(){
            if(CM.checkMobile() || CM.checkWindowsize()){
              return import(`../page/mobile/mypage.vue`)
            }else{
              return import('../page/pc/mypage.vue')
            }
          },
          meta: {
            title: 'マイページ',
            showTab: true
          },
      	},
        {
      		path: '/orderlist',
          name:"orderlist",
          component: () => import('../page/pc/orderlist.vue'),
          meta: {
            title: '予約履歴'
          },
      	},
        {
      		path: '/cardpage',
          name:"cardpage",
          component: () => import('../page/pc/cardPage.vue'),
          meta: {
            title: '決済情報入力ページ'
          },
      	},
        {
      		path: '/getreceipt',
          name:"getreceipt",
          component: () => import('../page/pc/getreceipt.vue'),
          meta: {
            title: '領収書'
          },
      	},
        {
      		path: '/couponlist',
          name:"couponlist",
          component: () => import('../page/pc/couponlist.vue'),
          meta: {
            title: '保有クーポン確認'
          },
      	},
        {
      		path: '/cardlist',
          name:"cardlist",
          component: () => import('../page/pc/cardlist.vue'),
          meta: {
            title: 'クレジットカード'
          },
      	},
        {
      		path: '/contactlist',
          name:"contactlist",
          component(){
            if(CM.checkMobile() || CM.checkWindowsize()){
              return import(`../page/mobile/contactlist.vue`)
            }else{
              return import('../page/pc/contactlist.vue')
            }
          },
          meta: {
            title: 'よく使う連絡先'
          },
      	},
        {
      		path: '/addcontact',
          name:"addcontact",
          component: () => import('../page/mobile/addcontact.vue'),
          meta: {
            title: '連絡先を追加'
          },
      	},
        {
      		path: '/checkinOrder',
          name:"checkinOrder",
          component: () => import('../page/pc/checkinOrder.vue'),
          meta: {
            title: 'チェックイン',
            showTab: true
          },
      	},
        {
      		path: '/orderdetail',
          name:"orderdetail",
          component(){
            if(CM.checkMobile() || CM.checkWindowsize()){
              return import(`../page/mobile/orderdetail.vue`)
            }else{
              return import('../page/pc/orderdetail.vue')
            }
          },
          // component: () => import('../page/pc/orderdetail.vue'),
          meta: {
            title: '予約情報',
          },
      	},
        {
      		path: '/activepage',
          name:"activepage",
          component(){
            if(CM.checkMobile() || CM.checkWindowsize()){
              return import(`../page/mobile/activepage.vue`)
            }else{
              return import('../page/pc/activepage.vue')
            }
          },
          // component: () => import('../page/pc/orderdetail.vue'),
          meta: {
            title: 'イベントページ',
          },
      	},
        {
      		path: '/topaypage',
          name:'topaypage',
      		component: () => import('../page/pc/topaypage.vue'),
          meta: {
            title: '支払いページ',
          },
      	},
        {
      		path: '/membership',
          name:'membership',
      		component: () => import('../page/pc/membership.vue'),
          meta: {
            title: '会員ランクについて',
          },
      	},
        {
      		path: '/aboutus',
          name:'aboutus',
      		component: () => import('../page/pc/aboutus.vue'),
          meta: {
            title: '会社概要',
          },
      	},
        {
          path: '/payment',
          name:'payment',
          component: () => import('../page/pc/payment.vue'),
          meta: {
            title: 'クレジットカード情報入力',
          },
        },
        {
      		path: '/userinfo',
          name:'userinfo',
      		component: () => import('../page/pc/userinfo.vue'),
          meta: {
            title: '個人情報',
          },
      	},
        {
      		path: '/settings',
          name:'settings',
      		component: () => import('../page/pc/settings.vue'),
          meta: {
            title: 'Settings',
          },
      	},
        {
      		path: '/sign-in',
          name:'signin',
      		component: () => import('../page/login.vue'),
          meta: {
            title: 'MR.T会員登録',
          },
      	}
      ]
    },
    {
  		path: '/paypage',
      name:'paypage',
  		component: () => import('../page/pc/paypage.vue'),
      meta: {
        title: '支払いページ',
      },
  	},
    {
  		path: '/paysuccess',
      name:'paysuccess',
  		component: () => import('../page/mobile/paysuccess.vue'),
      meta: {
        title: '支払成功',
      },
  	},
    {
  		path: '/payfailed',
      name:'payfailed',
  		component: () => import('../page/mobile/payfailed.vue'),
      meta: {
        title: '支払失敗',
      },
  	},
    {
  		path: '/cancelpage',
      name:'cancelpage',
  		component: () => import('../page/pc/cancelpage.vue'),
      meta: {
        title: 'キャンセル',
      },
  	}
  ],
})
// 全局路由守卫
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('loginData');
    if (to.meta.title) {
      document.title = `${to.meta.title}`;
    }
    if (to.path == '/sign-in') {
        // 登录或者注册才可以往下进行
        if(token){
          next({
  					path: '/index'
  				})
        }else{
  				next();
  			}
    } else {
      if(to.path == '/bookpage' || to.path == '/paypage' || to.path == '/checkinOrder' || to.path == '/orderlist'){
        if(token){
          next();
        }else{
          next({
            path: '/sign-in'
          })
        }
      }else{
        next();
      }
    }
});
export default router
