import axios from 'axios';
import router from '../router'
const http = axios.create({
    timeout: 300000, //超时时长5分钟,
    crossDomain: true
});
var API_URL = "api/";
if(process.env.NODE_ENV == 'development'){
	API_URL = "api/api/";
}else{
	API_URL = "api/";
}
//当前正在请求的数量
let needLoadingRequestCount = 0;

// let logindata = {};
// let iflogin = false;
// if(localStorage.getItem('loginData')){
// 	logindata = JSON.parse(localStorage.getItem('loginData'));
// 	iflogin = true;
// }else{
// 	logindata = {};
// 	iflogin = false;
// }
// console.log("servelogindata",logindata);
//添加请求拦截器
let tempConfig = '';
let nTime=Date.parse( new Date())/1000;
let callbacks = []
function onAccessTokenFetched(newToken) {
    callbacks.forEach(callback => {
        callback(newToken)
    })
    // 清空缓存接口
    callbacks = []
}

// 添加缓存接口
function addCallbacks(callback) {
    callbacks.push(callback)
}
http.interceptors.request.use(config => {
	let logindata = {};
	let iflogin = false;
	if(localStorage.getItem('loginData')){
		logindata = JSON.parse(localStorage.getItem('loginData'));
		iflogin = true;
	}else{
		logindata = {};
		iflogin = false;
	}
	if(iflogin){
		config.headers.Authorization = logindata.token_type +" "+logindata.access_token;
	}else{
		config.headers.Authorization = '';
	}
	tempConfig = config;
	return config;
}, err => {
    // 请求发生错误时的相关处理 抛出错误
   Promise.reject(err)
})

let isRefreshToken = false; // 正在刷新token
http.interceptors.response.use(
    response => {
		console.log("http.interceptors.responseresponse",response.data);
		
			return response.data;
    },
    error => {
		if(error.response&&error.response.status){
			switch (error.response.status) {
				//401: 未登录-授权过期
				case 401:
				//如有刷新token的需求,可放开以下代码
				let config = error.response.config
				const retryOriginalRequest = new Promise(resolve => {
					 addCallbacks(newToken => {
							 // 表示用新的token去替换掉原来的token
							 config.headers.Authorization = newToken
							 resolve(http.request(config)) // 调用resolve请求队列里面接口
					 })
			 	})
				let logindata = {};
				let iflogin = false;
				if(localStorage.getItem('loginData')){
					logindata = JSON.parse(localStorage.getItem('loginData'));
					iflogin = true;
				}else{
					logindata = {};
					iflogin = false;
				}
				if (!iflogin) {
		      return;
		    }
				if (!isRefreshToken) {
					const newhttp = axios.create({
					    timeout: 300000, //超时时长5分钟,
					    crossDomain: true
					});
					let logindata = {};
					let iflogin = false;
					if(localStorage.getItem('loginData')){
						logindata = JSON.parse(localStorage.getItem('loginData'));
						iflogin = true;
					}else{
						logindata = {};
						iflogin = false;
					}
            		newhttp.post('api/login/custRefreshToken', {
							"scope":"hotel_web",
							"client_id":"hotel_web",
							"client_secret":"hotel_web",
							"grant_type":"refresh_token",
							"refresh_token":logindata.refreshToken
				    }).then(resp => {  // 用refreshToken获取新的token
						if(resp.data && resp.data.access_token){
							isRefreshToken = true;
							let obj={
								access_token:resp.data.access_token,
								usename:resp.data.usename,
								token_type:resp.data.token_type,
								timestamp1:Date.parse( new Date())/1000,
								memberLevel:resp.data.memberLevel,
								refreshToken:resp.data.refresh_token
							}
							console.log("刷新then",resp);
							localStorage.setItem('loginData', JSON.stringify(obj));
							logindata.access_token = resp.data.access_token;
							logindata.refreshToken = resp.data.refresh_token;
							logindata.token_type = resp.data.token_type;
                			onAccessTokenFetched(resp.data.token_type+" "+resp.data.access_token)
						}else{
							localStorage.removeItem('loginData');
							localStorage.removeItem('topayparams');
							localStorage.removeItem('activedata');
							localStorage.removeItem('userdata');
							localStorage.removeItem('payparams');
							router.push('/sign-in');
						}

            }).catch((err) => {
                // 刷新token错误跳转到登陆页面
				console.log("刷新catch",err);
                localStorage.removeItem('loginData');
                localStorage.removeItem('topayparams');
                localStorage.removeItem('activedata');
				localStorage.removeItem('userdata');
                localStorage.removeItem('payparams');
				router.push('/sign-in');
            }).finally(() => {
                isRefreshToken = false
            })
        }
				return retryOriginalRequest;
				break;
				case 500:

				break;
				case 503:

				break;
			}
			return Promise.reject(error.response);
		}
		  if (axios.isCancel(error)) {
      		//取消请求
    	  } else {

    		}
        return Promise.reject(error.response);
    }
);

export default http;
