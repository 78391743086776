import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App);
import router from './router'
import '@vant/touch-emulator';
import CM from './util/common.js'
import { Locals } from "./util/vantlocal.js";
import { ConfigProvider } from 'vant';
import { Button,NavBar,Popup,Switch, CouponCell,Picker,Image as VanImage, CouponList ,Swipe,SwipeCell,ActionSheet,Divider,ContactCard, SwipeItem,Dialog as VanDialog,Tag as Vtag, Form,ActionBar,BackTop, Cell,AddressList, Stepper,CellGroup,ActionBarIcon, Icon,ActionBarButton, Field, DatePicker, Calendar,Toast ,TextEllipsis,SubmitBar,Tab, Tabs, Tabbar,CountDown,List, TabbarItem ,Overlay,ContactList,Empty,RadioGroup, Radio,DropdownMenu, DropdownItem,Collapse, CollapseItem} from 'vant';
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
//icons
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"
import 'vant/lib/index.css';
import './assets/theme.css';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Menubar from 'primevue/menubar';
import Carousel from 'primevue/carousel';
import Menu from 'primevue/menu';
import Pbutton from 'primevue/button';
import Avatar from 'primevue/avatar';
import Listbox from 'primevue/listbox';
import SpeedDial from 'primevue/speeddial';
import Dropdown from 'primevue/dropdown';
import BlockUI from 'primevue/blockui';
import FocusTrap from 'primevue/focustrap';
import CascadeSelect from 'primevue/cascadeselect';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Dialog from 'primevue/dialog';
import Galleria from 'primevue/galleria';
import Card from 'primevue/card';
import Tag from 'primevue/tag';
import InputNumber from 'primevue/inputnumber';
import ToastService from 'primevue/toastservice';
import Ptoast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Tooltip from 'primevue/tooltip';
import VueCreditCardValidation from 'vue-credit-card-validation';
import VueTheMask from 'vue-the-mask'
import DataTable from 'primevue/datatable';
import InputSwitch from 'primevue/inputswitch';
import Column from 'primevue/column';
import OverlayPanel from 'primevue/overlaypanel';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';                   // optional
import ja from "./local/ja"
import $ from 'jquery';
// import "bootstrap";
import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { AES_Encrypt, AES_Decrypt } from './util/aes.js'
import req from "./util/server.js";
import axios from 'axios'
import Api from './api/Api.js'
app.provide('$axios', axios)
app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);
// app.directive('mask', mask);
app.config.globalProperties._Api = Api;
app.config.globalProperties.$Local = Locals;
app.config.globalProperties.CM = CM;
app.config.globalProperties.$AES_Encrypt = AES_Encrypt //全局加密
app.config.globalProperties.$req = req;
app.use(PrimeVue, { ripple: true })
app.use(VueCreditCardValidation)
app.use(VueTheMask)
app.component('InputText', InputText);
app.component('Menubar', Menubar);
app.component('Carousel', Carousel);
app.component('Menu', Menu);
app.component('Pbutton', Pbutton);
app.component('Avatar', Avatar).component('Listbox', Listbox).component('SpeedDial', SpeedDial).component('Card', Card)
.component('Dropdown', Dropdown).component('CascadeSelect', CascadeSelect).component('BlockUI', BlockUI).component('OverlayPanel', OverlayPanel)
.component('DataView', DataView).component('DataViewLayoutOptions', DataViewLayoutOptions).component('Tag', Tag).component('RadioButton', RadioButton)
.component('Dialog', Dialog).component('Galleria', Galleria).component('InputNumber', InputNumber).component('Ptoast',Ptoast).component('ConfirmDialog',ConfirmDialog)
.component('DataTable', DataTable).component('Column', Column).component('ColumnGroup', ColumnGroup).component('Row',Row).component("InputSwitch",InputSwitch);
app.directive('focustrap', FocusTrap);
app.use(router).use(ToastService).use(ConfirmationService).use(SubmitBar).use(TextEllipsis).use(Button).use(Form).use(Field).use(RadioGroup).use(DropdownItem).use(Divider)
.use(CellGroup).use(DatePicker).use(Calendar).use(Toast).use(ActionBar).use(ActionBarIcon).use(ActionBarButton).use(Cell).use(Radio).use(DropdownMenu).use(ConfigProvider)
.use(Tab).use(Tabs).use(Icon).use(Stepper).use(Tabbar).use(TabbarItem).use(BackTop).use(VanDialog).use(Vtag).use(NavBar).use(Swipe).use(SwipeItem).use(Empty).use(VanImage)
.use(ActionSheet).use(CouponCell).use(AddressList).use(CouponList).use(Popup).use(CountDown).use(Overlay).use(ContactCard).use(Picker).use(Switch).use(SwipeCell).use(ContactList).use(List)
.use(Collapse).use(CollapseItem);
app.mount('#app')
console.log = function(){

};
